import request from '@/core/services/api.services.request'
class Errors {
  constructor() {
    this.errors = {}
  }
  add(field, value){
    this.errors[field] = [value];
  }
  has(field) {
    let status = false;
    if(this.errors){
      for (var prop in this.errors) {
          if ( this.errors.hasOwnProperty(field) && prop.toLowerCase() == field.toLowerCase() ) {
              status = true;
              break;
          }
      }
    }
    return status;
  }
  any() {
    return Object.keys(this.errors).length > 0
  }
  get(field) {
    let errMsg = '';
    if(this.errors){
      for (var prop in this.errors) {
          if ( this.errors.hasOwnProperty(field) && prop.toLowerCase() == field.toLowerCase() ) {
              errMsg = this.errors[field][0];
              break;
          }
      }
    }
    return errMsg;
  }
  record(errors) {
    this.errors = errors
  }
  clear(field) {
    if (field) {
      delete this.errors[field]
      return
    }
    this.errors = {}
  }
}

export default class Form {
  constructor(data) {
    this.originalData = data
    for (const field in data) {
      this[field] = data[field]
    }
    this.errors = new Errors()
    this.submiting = false
  }
  data() {
    /*const data = {}
    for (const property in this.originalData) {
      if (this[property] !== null) {
        data[property] = this[property]
      }
    }
    return data*/
    
    let data = new FormData();
    for (let property in this.originalData) {
      if (this[property] !== null) {
            let value = this[property]
            var arrayData = [
              'selected_invoice',
              'permissions',
              'billing_addresses',
              'shipping_addresses',
              'upc_list',
              'supplier_list',
              'mgf_list',
              'products',
              'attachments',
              'delivery_items',
              'payment',
              'estimate_items',
              'quotation_items',
              'customer_address'
             
            ]
            
            if(arrayData.indexOf(property) != -1){
              value = JSON.stringify(value);
              data.append(property, value);
            }else{
              data.append(property, this[property]);
            }
        }
      }
    return data;
    /*$.each(this.originalData, function(key, value){
      if (typeof(value) === 'object') {
          value = new Blob([JSON.stringify(value)], {type : 'application/json'});// or just JSON.stringify(value)
      }
      data.append(key, value);
      console.log("data", this.originalData);
      
    });
    return data;*/

  }
  reset() {
    for (const field in this.originalData) {
      this[field] = this.originalData[field]
    }
    this.errors.clear()
  }
  post(url) {
    return this.submit('post', url)
  }
  submit(requestType, url) {
    this.submiting = true
    return new Promise((resolve, reject) => {
      request({
        url: url,
        method: requestType,
        data: this.data()
      })
        .then(response => {
          this.submiting = false
          this.onSuccess()
          resolve(response.data)
        })
        .catch(error => {
          this.submiting = false
          this.onFail(error.data.error)
          reject(error.data.error)
        })
    })
  }
  postBallData(url) {
    this.submiting = true
    return new Promise((resolve, reject) => {
      request({
        url: url,
        method: 'post',
        data: this.data()
      })
        .then(response => {
          this.submiting = false
          //this.onSuccess()
          resolve(response.data)
        })
        .catch(error => {
          this.submiting = false
          this.onFail(error.data.error)
          reject(error.data.error)
        })
    })
  }
  onSuccess() {
    this.reset()
  }
  onFail(errors) {
    this.errors.record(errors)
  }
}
